import React, { useState } from 'react';
import { TextField, Button, Alert } from '@mui/material';
import axios from 'axios';

const PostcodeInput = ({ updateMap, setData }) => {
  const [postcode, setPostcode] = useState('');
  const [error, setError] = useState('');

  const handlePostcodeChange = (e) => {
    setPostcode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!postcode) {
      setError('Please enter a postcode');
      return;
    }

    axios.get(`https://api.postcodes.io/postcodes/${postcode}`)
      .then(response => {
        const { latitude, longitude } = response.data.result;
        updateMap(latitude, longitude);
        setError('');

        // Fetch additional data related to the postcode
        axios.get(`https://europe-west2-ramsen-testing.cloudfunctions.net/testing_gcf?postcode=${postcode}`)
          .then(res => {
            setData(res.data.body); // Assuming res.body contains the relevant data
          })
          .catch(err => {
            console.error('Error fetching data for deliveroo', err);
          });
      })
      .catch(error => {
        setError('There was an error fetching the coordinates. Please check the postcode and try again.');
        console.error('There was an error fetching the coordinates!', error);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="input-form">
      <TextField
        label="Postcode"
        value={postcode}
        onChange={handlePostcodeChange}
        fullWidth
        margin="normal"
        error={!!error}
        helperText={error}
      />
      <Button variant="contained" color="primary" type="submit">
        Update Map
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
    </form>
  );
};

export default PostcodeInput;
