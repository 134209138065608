import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, Box, Toolbar, AppBar , useMediaQuery} from '@mui/material';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme, darkTheme } from './theme';
import MapContainer from './MapContainer';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : theme}>
      <CssBaseline />
      <TopBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Your Brand Name
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Box sx={{ display: 'flex' }}>
        {!isMobile && <Sidebar />}
        <Box component="main" sx={{ flexGrow: 1, paddingTop: 2, paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar />
          <Container maxWidth={false} className="App" sx={{ paddingTop: 2, paddingLeft: 0, paddingRight: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper className="paper">
                  <Typography variant="h5" gutterBottom>
                    Map Visualisation
                  </Typography>
                  <MapContainer setData={setData} setLoading={setLoading}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className="paper">
                  <Typography variant="h5" gutterBottom>
                    Planning Applications
                  </Typography>
                  <div className="scrollable-card">
                    {data.map((item, index) => (
                      <Paper className="inner-card" key={index}>
                        <Typography variant="body2">
                          <strong>Title:</strong> {item.title} <br />
                          <strong>Address:</strong> {item.address} <br />
                          <strong>Meta Info:</strong> {item.meta_info} <br />
                          <strong>Postcode:</strong> {item.postcode} <br />
                          <a href={item.URL} target={item.URL} rel="noopener noreferrer">View Details</a>
                        </Typography>
                      </Paper>
                    ))}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
