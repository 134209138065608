import React, { useState } from 'react';
import Map from './Map';
import PostcodeInput from './PostcodeInput';

const MapContainer = ({ setData }) => {
  const [lat, setLat] = useState(51.505);
  const [lon, setLon] = useState(-0.09);

  const updateMap = (latitude, longitude) => {
    setLat(latitude);
    setLon(longitude);
  };

  return (
    <div className="Map-container">
      <PostcodeInput updateMap={updateMap} setData={setData} />
      <div className="Map-wrapper">
        <Map lat={lat} lon={lon} />
      </div>
    </div>
  );
};

export default MapContainer;
